import React from 'react';
import styles from './Selection.module.css';
import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import { useNavigate } from 'react-router-dom';
import digid from './images/digid.svg';
import eherkenning from './images/eherkenning.svg';
import yivi from './images/yivi.svg';

export default function Selection() {
  let navigate = useNavigate();

  return (
    <div className={styles.main}>
      <div className={styles.title}>Inloggen</div>
      <p>U kunt kiezen om met DigiD, eHerkenning of met Yivi in te loggen.</p>

      <ButtonContainer column>
        <Button>
          <img className={styles.method} style={{backgroundColor:'black'}} src={digid} alt="" />
          Inloggen met DigID
        </Button>
        <Button>
          <img className={styles.method} src={eherkenning} alt="" />
          Inloggen met eHerkenning
        </Button>
        <Button>
          <img className={styles.method} src={yivi} alt="" />
          Login met Yivi als persoon (nieuw)
        </Button>
        <Button
          onClick={() => {
            navigate('../info');
          }}
        >
          <img className={styles.method} src={yivi} alt="" />
          Login met Yivi als organisatie (nieuw)
        </Button>
      </ButtonContainer>
      {/* <p>
        Door in te loggen geeft u uw BSN (BurgerServiceNummer) door. Dit is een
        uniek nummer waaraan uw persoonlijke gegevens zijn gekoppeld. Wij vragen
        uw BSN zodat u alleen uw gegevens ziet. Met Yivi ziet u welke gegevens
        van u gevraagd worden als u inlogt. Lees meer over Yivi.
      </p> */}
    </div>
  );
}
