import React from 'react';
import styles from './Container.module.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import IrmaDisclose from './IrmaDisclose';
// import logo from './images/logo.png';

export default function Container() {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {/* <img style={{width:'36px',height:'32px', paddingRight:'6px'}} src={logo} alt="" /> */}
          <span className={styles.logo} style={{}}>
            demo
          </span>
          <div className={styles.logoText}>datadelen.nl (Dummy)</div>
        </div>
        {/* <div>
          <a href="">log in</a>
        </div> */}
      </div>
      <div className={styles.block}>
        <Routes caseSensitive>
          <Route path="" element={<Home />}></Route>
          <Route path="disclose" element={<IrmaDisclose />}></Route>
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </div>
    </div>
  );
}
