import React from 'react';
import styles from './Home.module.css';

import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import { useNavigate } from 'react-router-dom';
// import labels from '../resources/labels';

export default function Home() {
  let navigate = useNavigate();

  return (
    <div className={styles.main}>
      <div>
        <h1 className={styles.title}>
          Deel je gegevens in jouw sector (Dummy)
        </h1>
        <p>
          Dit is bijvoorbeeld een platform waar organisaties gegevens kunnen delen
          met elkaar in hun sector. Maar voordat je gegevens kan inzien van
          andere organisaties, dient het platform wel te weten namens welke
          organisatie je handelt.
        </p>
        {/* <p>
          Je kan op twee manieren inloggen. 
        </p>  
        <ul>
          <li>Met je KVK kaartje</li>
          <li>Met je Machtigingskaartje voor 'Toegang tot JoinData portal'</li>
        </ul> */}

        <ButtonContainer>
          <Button
            onClick={() => {
              navigate('disclose');
            }}
          >
            Login als organisatie
          </Button>
          <Button
            onClick={() => {
              navigate('demo');
            }}
          >
            Login als organisatie (demo)
          </Button>
        </ButtonContainer>
        <p>
          Sta je niet ingeschreven bij de KVK als eigenaar of bestuurder van je
          organisatie, vraag dan een machtiging aan bij degene, die wel staat
          ingeschreven bij de KVK.
        </p>
        <ButtonContainer>
          <Button
            onClick={() => {
              window.open('https://organisatiegegevens.signicat.dev/getauthorization/requestauthorization?authorization=joindata-access_to_portal', '_blank');
            }}
          >
            Machtiging aanvragen (demo)
          </Button>
          {/* <Button
            onClick={() => {
              window.location.href =
                'http://192.168.139.200:3000/getauthorization/paymentstart';
            }}
          >
            Machtiging aanvragen
          </Button>
          <a href="http://192.168.139.200:3000/getauthorization/paymentstart" target="_blank" rel="noopener noreferrer">test</a> */}
        </ButtonContainer>
      </div>
    </div>
  );
}
