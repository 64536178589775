import React from 'react';
import styles from './Home.module.css';
import logo from './images/logo.png';
import yiviLogo from './images/yivi-logo.png';

import { useNavigate } from 'react-router-dom';
// import labels from '../resources/labels';

export default function Home() {
  let navigate = useNavigate();

  return (
    <div className={styles.main}>
      <div className={styles.panel}>
        <img style={{ width: '200px' }} src={logo} alt="" />
        <h3 className={styles.title}>Welkom bij JoinData</h3>

        <div
          className={styles.yiviButton}
          onClick={() => {
            navigate('/joindata/disclose');
          }}
        >
          <img style={{ width: '60px', paddingRight: '6px' }} src={yiviLogo} alt="" />
          <span>Login met Yivi</span>
        </div>
      </div>
    </div>
  );
}
