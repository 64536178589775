import React from 'react';
import styles from './ButtonContainer.module.css';

export default function ButtonContainer({ children, column, ...props }) {
  return (
    <div
      style={column ? { flexDirection: 'column' } : {}}
      className={styles.main}
      {...props}
    >
      {children}
    </div>
  );
}
