import React from 'react';
import styles from './Home.module.css';

import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import { useNavigate } from 'react-router-dom';
// import labels from '../resources/labels';

export default function Home() {
  let navigate = useNavigate();

  return (
    <div className={styles.main}>
      <div>
        <h1 className={styles.title}>Deel je UBO gegevens (Demo)</h1>
        <p>
          Dit is bijvoorbeeld een partij die WWFT plichtig is en in hun KYC
          proces willen controleren of de gebruiker UBO is. De gebuiker deelt
          zijn UBO gegevens via zijn Yivi wallet app.
        </p>

        <ButtonContainer>
          <Button
            onClick={() => {
              navigate('/ubo/disclose');
            }}
          >
            Deel je UBO gegevens
          </Button>
        </ButtonContainer>
      </div>
    </div>
  );
}
