import React, { useState, useEffect } from 'react';
import styles from './IrmaDisclose.module.css';
import yivi from '@privacybydesign/yivi-frontend';
import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import { useNavigate } from 'react-router-dom';
// import labels from '../resources/labels';

let fields = ['kvkNummer', 'functie', 'reden'];
let fieldsMachtiging = [
  'kvkNummer',
  'naam',
  'geslachtsnaam',
  'voorvoegselGeslachtsnaam',
  'voornamen',
  'authorizationCode',
  'authorizationDescription',
  'authorizationDate',
  'issuedBy',
];
let fieldsb = [
  'soortBevoegdheid',
  'beperkingInEurosBevoegdheid',
  'overigeBeperkingBevoegdheid',
  'isBevoegdMetAnderePersonen',
  'typeVolmacht',
  'beperkingInGeldVolmacht',
  'beperkingInHandelingVolmacht',
  'heeftOverigeVolmacht',
  'omschrijvingOverigeVolmacht',
  'magOpgaveHandelsregisterDoen',
];
let translations = {
  kvkNummer: 'kvkNummer',
  naam: 'naam',
  volledigeNaam: 'volledigeNaam',
  functie: 'functie',
  isBevoegd: 'isBevoegd',
  reden: 'reden',
  soortBevoegdheid: 'soortBevoegdheid',
  beperkingInEurosBevoegdheid: 'beperkingInEurosBevoegdheid',
  overigeBeperkingBevoegdheid: 'overigeBeperkingBevoegdheid',
  isBevoegdMetAnderePersonen: 'isBevoegdMetAnderePersonen',
  typeVolmacht: 'typeVolmacht',
  beperkingInGeldVolmacht: 'beperkingInGeldVolmacht',
  beperkingInHandelingVolmacht: 'beperkingInHandelingVolmacht',
  heeftOverigeVolmacht: 'heeftOverigeVolmacht',
  omschrijvingOverigeVolmacht: 'omschrijvingOverigeVolmacht',
  magOpgaveHandelsregisterDoen: 'magOpgaveHandelsregisterDoen',

  geslachtsnaam: 'geslachtsnaam',
  voorvoegselGeslachtsnaam: 'voorvoegselGeslachtsnaam',
  voornamen: 'voornamen',
  authorizationCode: 'machtigingscode',
  authorizationDescription: 'omschrijving van de machtiging',
  authorizationDate: 'afgiftedatum van de machtiging',
  issuedBy: 'machtiging afgegeven door',
};

export default function IrmaDisclose({ mode }) {
  let navigate = useNavigate();
  const [disclosedData, setDisclosedData] = useState();
  // {
  //   beperkingInEurosBevoegdheid: '',
  //   beperkingInGeldVolmacht: '',
  //   beperkingInHandelingVolmacht: '',
  //   functie: '',
  //   heeftOverigeVolmacht: '',
  //   isBevoegd: 'Ja',
  //   isBevoegdMetAnderePersonen: '',
  //   kvkNummer: '54090385',
  //   magOpgaveHandelsregisterDoen: '',
  //   naam: 'Mayer Software Development',
  //   omschrijvingOverigeVolmacht: '',
  //   overigeBeperkingBevoegdheid: '',
  //   reden: 'De persoon Michiel Dirk  Maijer is eigenaar van een eenmanszaak',
  //   soortBevoegdheid: '',
  //   typeVolmacht: '',
  //   volledigeNaam: 'Michiel Dirk Maijer',
  // }
  // {
  //   beperkingInEurosBevoegdheid: '',
  //   beperkingInGeldVolmacht: '',
  //   beperkingInHandelingVolmacht: '',
  //   functie: 'Bestuurder',
  //   heeftOverigeVolmacht: '',
  //   isBevoegd: 'Niet vastgesteld',
  //   isBevoegdMetAnderePersonen: 'Nee',
  //   kvkNummer: '55333672',
  //   magOpgaveHandelsregisterDoen: '',
  //   naam: 'Vereniging van eigenaars gebouw Hudsonstraat 52 te Amsterdam',
  //   omschrijvingOverigeVolmacht: '',
  //   overigeBeperkingBevoegdheid: '',
  //   reden: 'Michiel Dirk  Maijer (Bestuurder) is Alleen/zelfstandig bevoegd',
  //   soortBevoegdheid: 'Alleen/zelfstandig bevoegd',
  //   typeVolmacht: '',
  //   volledigeNaam: 'Michiel Dirk Maijer',
  // }
  useEffect(() => {
    if (disclosedData) return null;

    let options = {
      debugging: false,
      element: '#irma-web-form',
      session: {
        start: {
          url: (o) => {
            let url = `${o.url}/api/session/disclose`;
            if (mode === 'demo') url += '?mode=demo';
            return url;
          },
        },
        mapping: {
          sessionPtr: (r) => r.sessionPtr,
          sessionToken: (r) => r.sessionToken,
          frontendRequest: (r) => r.frontendRequest,
        },
        result: {
          url: (o, { sessionPtr, sessionToken }) => {
            return `${o.url}/api/session/${sessionToken}/result?mode=${mode}`;
          },
          credentials: 'include',
          parseResponse: (r) => r.json(),
        },
      },
    };

    const yiviInstance = yivi.newWeb(options);
    yiviInstance
      .start()
      .then((response) => {
        if (response.error) {
          console.log(response.error);
          return;
        }
        console.log('session completed', JSON.stringify(response));
        setDisclosedData(response);
      })
      .catch((error) => {
        // irma frontend bug: REST errors are not catched here
        console.log(error.response);
      });
  }, []);

  function renderDisclosedData() {
    if (disclosedData.authorizationCode) {
      return renderMachtiging();
    }
    return renderBevoegdheid();
  }

  function renderMachtiging() {
    let d = disclosedData;

    return (
      <div style={{ padding: '12px 0' }}>
        <div>
          <h1>{`Beste ${d.geslachtsnaam}`}</h1>
          <div>We hebben je machtiging ontvangen!</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                width: '30px',
                height: '30px',
                backgroundColor: 'green',
                borderRadius: '50%',
                marginRight: '12px',
                flexShrink: '0',
              }}
            ></span>
            <p>
              Je bent gemachtigd voor{' '}
              <strong>{d.authorizationDescription}</strong> namens{' '}
              <strong>{d.naam}</strong>
            </p>
          </div>
        </div>

        <table>
          <tbody>
            {fieldsMachtiging.map((field) => {
              return (
                <tr key={field}>
                  <td style={{ fontSize: '0.8em', opacity: '0.5' }}>
                    {translations[field]}
                  </td>
                  <td style={{ fontSize: '0.9em', fontWeight: '500' }}>
                    {disclosedData[field]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ButtonContainer>
          <Button
            color="white"
            onClick={() => {
              navigate('/')
            }}
          >
            Terug
          </Button>
          <Button>Verder</Button>
        </ButtonContainer>
      </div>
    );
  }
  function renderBevoegdheid() {
    let d = disclosedData;
    let subTitle = `Je bent niet bevoegd voor ${d.naam}`;
    let color = 'red';
    if (d.isBevoegd === 'Ja') {
      subTitle = `Je bent bevoegd voor ${d.naam}`;
      color = 'green';
    } else if (d.isBevoegd === 'Niet vastgesteld') {
      subTitle = `Je bevoegdheid voor ${d.naam} is niet vastgesteld`;
      color = 'orange';
    }
    return (
      <div style={{ padding: '12px 0' }}>
        <div>
          <h1>{`Beste ${d.volledigeNaam}`}</h1>
          <div>We hebben je KVK bevoegdheidsgegevens ontvangen!</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                width: '30px',
                height: '30px',
                backgroundColor: color,
                borderRadius: '50%',
                marginRight: '12px',
                flexShrink: '0',
              }}
            ></span>
            <h4>{subTitle}</h4>
          </div>
        </div>

        <table>
          <tbody>
            {fields.map((field) => {
              return (
                <tr key={field}>
                  <td style={{ fontSize: '0.8em', opacity: '0.5' }}>
                    {translations[field]}
                  </td>
                  <td style={{ fontSize: '0.9em', fontWeight: '500' }}>
                    {disclosedData[field]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div style={{ padding: '24px 0' }}>
          Als de bevoegdheid niet is vastgesteld, dan gebruiken we onderstaande
          velden om te bepalen of je 'bevoegd genoeg' bent voor onze service{' '}
        </div>
        <table>
          <tbody>
            {fieldsb.map((field) => {
              return (
                <tr key={field}>
                  <td style={{ fontSize: '0.8em', opacity: '0.5' }}>
                    {translations[field]}
                  </td>
                  <td style={{ fontSize: '0.9em', fontWeight: '500' }}>
                    {disclosedData[field]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
        <ButtonContainer>
          <Button
            color="white"
            onClick={() => {
              navigate('/')
            }}
          >
            Terug
          </Button>
          <Button>Verder</Button>
        </ButtonContainer>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {!disclosedData && (
        <div>
          <div>
            <h1 className={styles.title}>Log in als organisatie</h1>
          </div>
          <p style={{ fontSize: '1.2em' }}>Deel je KVK bevoegdheidsgegevens</p>
          <div className={styles.irma}>
            <div id="irma-web-form"></div>
          </div>
          <ButtonContainer>
            <Button
              color="white"
              onClick={() => {
                navigate('/');
              }}
            >
              Terug
            </Button>
          </ButtonContainer>
        </div>
      )}

      {disclosedData && renderDisclosedData()}
    </div>
  );
}
