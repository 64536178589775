import React from 'react';
import styles from './Container.module.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import IrmaDisclose from './IrmaDisclose';
import Selection from './Selection';
import Info from './Info';
// import AuthType from './AuthType';
import logo from './images/logo-nijmegen.svg';

export default function Container({prod}) {
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <img
            style={{ width: '136px', height: '45px', paddingRight: '6px' }}
            src={logo}
            alt=""
          />
          {/* <span className={styles.logo} style={{}}>
            demo
          </span> */}
          {/* <div className={styles.logoText}>datadelen.nl (Dummy)</div> */}
        </div>
        <div className={styles.menu}>
          <a href="">Diensten</a>
          <a href="">Nieuws</a>
          {/* <a href="">Over de gemeente</a> */}
          <a href="">Contact</a>
        </div>
      </div>
      <div className={styles.block}>
        <Routes caseSensitive>
          <Route path="" element={<Home />}></Route>
          <Route path="selection" element={<Selection />}></Route>
          <Route path="info" element={<Info prod={prod}/>}></Route>
          {/* <Route path="authtype" element={<AuthType />}></Route> */}
          <Route path="disclose" element={<IrmaDisclose prod={prod}/>}></Route>
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </div>
    </div>
  );
}
