import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Nijmegen from './components/nijmegen/Container';
import KVK from './components/kvk/Container';
import UBO from './components/ubo/Container';
import JoinData from './components/join-data/Container';

export default function App() {
  return (
    <Routes caseSensitive>
      <Route path="*" element={<Nijmegen />}></Route>
      <Route path="p/*" element={<Nijmegen prod/>}></Route>
      <Route path="kvk/*" element={<KVK />}></Route>
      <Route path="ubo/*" element={<UBO />}></Route>
      <Route path="joindata/*" element={<JoinData />}></Route>
      {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
    </Routes>
  );
}
