import React from 'react';
import styles from './Home.module.css';

import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import { useNavigate } from 'react-router-dom';
// import labels from '../resources/labels';

export default function Home() {
  let navigate = useNavigate();

  return (
    <div className={styles.main}>
      <p className={styles.trail}>
        <a href="">Home</a>
        <span className={styles.slash}>/</span>
        <a href="">Diensten</a>
        <span className={styles.slash}>/</span>
        <a href="">Evenementen</a>
        <span className={styles.slash}>/</span>
        <span>Evenement melden of vergunning aanvragen</span>
      </p>
      <div className={styles.title}>
        Evenement melden of vergunning aanvragen
      </div>
      <p>
        U mag een evenement melden of een vergunning aanvragen (via inloggen met
        DigiD, eHerkenning of Yivi). Lees eerst onderstaande informatie voordat
        u een aanvraag doet.
      </p>

      <ButtonContainer>
        <Button
          onClick={() => {
            navigate('selection');
          }}
        >
          Aanvragen
        </Button>
      </ButtonContainer>
    </div>
  );
}
