import React, { useState } from 'react';
import styles from './Info.module.css';
import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import { useNavigate } from 'react-router-dom';
import appStore from './images/app-store.png';
import googlePlay from './images/google-play.png';

export default function Info({ prod }) {
  let navigate = useNavigate();
  const [showMoreOfStep1, setShowMoreOfStep1] = useState();
  const [showMoreOfStep2, setShowMoreOfStep2] = useState();
  const [showMoreOfStep3, setShowMoreOfStep3] = useState();

  let issueUrl = prod
    ? 'https://organisatiegegevens.signicat.nl'
    : 'https://organisatiegegevens.signicat.dev';

  return (
    <div className={styles.main}>
      <div className={styles.title}>Inloggen met Yivi</div>
      <div style={{ padding: '24px 0' }}>
        <ButtonContainer>
          <Button
            onClick={() => {
              let mode = 'demokvkauth';
              if (prod) {
                mode = 'kvkauth';
              }
              navigate(`../disclose?mode=${mode}`);
            }}
            style={{ width: '100%', padding: '12px' }}
          >
            {`Inloggen met je organisatiegegevens`}
          </Button>
        </ButtonContainer>
      </div>

      <div className={styles.newContainer}>
        <div className={styles.title}>Nieuw bij Yivi?</div>
        <p>Ben je nieuw bij Yivi, neem de volgende stappen:</p>
        <div className={styles.stepContainer}>
          <div className={styles.step}>
            1 Download en installeer de Yivi-app
          </div>
          <div className={styles.stepContent}>
            <span>
              Yivi is een nieuwe manier van inloggen met een digitale identiteit
              wallet op je mobiele telefoon.
            </span>{' '}
            {showMoreOfStep1 ? (
              <span>
                <span>
                  Met Yivi kun je gegevens verzamelen bij officiële bronnen en
                  delen met organisaties of overheden, zoals Gemeente Nijmegen.
                  Yivi is een Nederlandse app, ontwikkeld aan de Radboud
                  universiteit, winnaar van de Privacy Award en ISO 29001
                  gecertificeerd.
                </span>{' '}
                <a
                  href="https://www.yivi.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Meer info over Yivi
                </a>
              </span>
            ) : (
              <a
                href="#1"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMoreOfStep1(true);
                }}
              >
                Lees meer
              </a>
            )}
            <div className={styles.downloadButtonContainer}>
              <a
                className={styles.downloadButton}
                href="https://apps.apple.com/nl/app/irma-authenticatie/id1294092994"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStore} alt="app store" />
              </a>
              <a
                className={styles.downloadButton}
                href="https://play.google.com/store/apps/details?id=org.irmacard.cardemu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googlePlay} alt="google play" />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.stepContainer}>
          <div className={styles.step}>2 Haal je persoonsgegevens op</div>
          <div className={styles.stepContent}>
            <span>
              Na installatie van de Yivi-app kun je je persoonsgegevens inladen.
              Dit doe je eenmalig en gebeurt via een DigID inlog bij de gemeente
              Nijmegen.
            </span>{' '}
            {showMoreOfStep2 ? (
              <span>
                <span>
                  Om je persoonsgegevens op te halen, druk in de Yivi-app op de
                  knop '<strong>Haal mijn persoonlijke gegevens op</strong>', en
                  vervolgens op knop '<strong>Persoonsgegevens</strong>'. Je
                  wordt geleid naar een pagina bij de gemeente Nijmegen waar je
                  inlogt met DigID.
                </span>{' '}
                <a
                  href="https://www.yivi.app/voor-mij/meer-informatie-over-ophalen-gegevens-gemeente"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Meer informatie
                </a>
              </span>
            ) : (
              <a
                href="#1"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMoreOfStep2(true);
                }}
              >
                Lees meer
              </a>
            )}
          </div>
        </div>
        <div className={styles.stepContainer}>
          <div className={styles.step}>3 Haal je organisatiegegevens op</div>
          <div className={styles.stepContent}>
            <span>
              Sta je in het KVK Handelsregister als eigenaar of bestuurder van
              je organisatie, dan kun je je KVK bevoegheidsgegvens toevoegen aan
              Yivi. Zo niet, dan kun je bij een eigenaar of bestuurder een
              machtiging aanvragen.
            </span>{' '}
            {showMoreOfStep3 ? (
              <span>
                <span>
                  Organisatiegegevens worden opgehaald via Signicat. Signicat is
                  de toonaangevende leverancier van digital identity oplossingen
                  in Europa.
                </span>{' '}
                <a
                  href="https://www.signicat.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Meer informatie over Signicat
                </a>
              </span>
            ) : (
              <a
                href="#1"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMoreOfStep3(true);
                }}
              >
                Lees meer
              </a>
            )}
          </div>
        </div>
        <div className={styles.blockContainer}>
          <a
            className={styles.block}
            href={issueUrl + '/getauthorization/info'}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <div className={styles.block}> */}
            <div className={styles.blockTitle}>
              Ik ben eigenaar of bestuurder van mijn organisatie
            </div>
            <div>en wil mijn KVK bevoegdheid toevoegen aan Yivi</div>
            {/* </div> */}
          </a>
          <a
            className={styles.block}
            href={
              issueUrl +
              '/getauthorization/infoauthorization?authorization=gemeente-nijmegen_aanvragen-vergunning-subsidie'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.blockTitle}>
              Ik ben{' '}
              <strong style={{ textDecoration: 'underline' }}>geen</strong>{' '}
              eigenaar of bestuurder van mijn organisatie
            </div>
            <div>en wil mijn machtiging toevoegen aan Yivi</div>
          </a>
        </div>
        {/* <ButtonContainer>
          <Button
            onClick={() => {
              navigate('../authtype');
            }}
          >
            {`Ik ben eigenaar of bestuurder en wil mijn KVK bevoegdheid toevoegen aan Yivi.`}
          </Button>
          <Button
            onClick={() => {
              navigate('../authtype');
            }}
          >
            {`Ik ben niet een eigenaar of bestuurder en wil een machtiging toevoegen aan Yivi.`}
          </Button>
        </ButtonContainer> */}
      </div>
    </div>
  );
}
