import React, { useState, useEffect } from 'react';
import styles from './IrmaDisclose.module.css';
import yivi from '@privacybydesign/yivi-frontend';
import Button from '../Button';
import ButtonContainer from '../ButtonContainer';
import { useNavigate } from 'react-router-dom';
// import labels from '../resources/labels';


let fieldsUBO = [
  'kvkNumber',
  'name',
  'legalEntity',
  'fullname',
  'authority',
  'officeAddress',
  'position',
  'officePhone',
  'sbiCode',
];

let translations = {
  kvkNumber: 'KVK nummer',
  name: 'Naam organisatie',
  legalEntity: 'Rechstvorm',
  fullname: 'Naam UBO',
  authority: 'BSN',
  officeAddress: 'Adres',
  position: 'Belang',
  officePhone: 'Omvang van het belang',
  sbiCode: 'Ingangsdatum van het belang',

  
};

export default function IrmaDisclose({ mode }) {
  let navigate = useNavigate();
  const [disclosedData, setDisclosedData] = useState();
  // {
  //   kvkNumber: '12345678',
  //   name: 'Witbaard Feestartikelen',
  //   legalEntity: 'Besloten Venootschap',
  //   fullname: 'Jan Klaasen',
  //   authority: bsn,
  //   officeAddress: 'Dorpstraat 4, Lutjebroek',
  //   position: 'Aandeelhouder',
  //   officePhone: 'Groter dan 75% (en kleiner dan gelijk aan 100%)',
  //   sbiCode: '14 september 2020',
  // }
  useEffect(() => {
    if (disclosedData) return null;

    let options = {
      debugging: false,
      element: '#irma-web-form',
      session: {
        start: {
          url: (o) => {
            let url = `${o.url}/api/session/disclose?mode=ubo`;
            return url;
          },
        },
        mapping: {
          sessionPtr: (r) => r.sessionPtr,
          sessionToken: (r) => r.sessionToken,
          frontendRequest: (r) => r.frontendRequest,
        },
        result: {
          url: (o, { sessionPtr, sessionToken }) => {
            return `${o.url}/api/session/${sessionToken}/result?mode=ubo`;
          },
          credentials: 'include',
          parseResponse: (r) => r.json(),
        },
      },
    };

    const yiviInstance = yivi.newWeb(options);
    yiviInstance
      .start()
      .then((response) => {
        if (response.error) {
          console.log(response.error);
          return;
        }
        console.log('session completed', JSON.stringify(response));
        setDisclosedData(response);
      })
      .catch((error) => {
        // irma frontend bug: REST errors are not catched here
        console.log(error.response);
      });
  }, []);



  function renderDisclosedData() {
    let d = disclosedData;

    return (
      <div style={{ padding: '12px 0' }}>
        <div>
          <h1>{`Beste ${d.fullname}`}</h1>
          <div>We hebben je UBO gegevens ontvangen!</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                width: '30px',
                height: '30px',
                backgroundColor: 'green',
                borderRadius: '50%',
                marginRight: '12px',
                flexShrink: '0',
              }}
            ></span>
            <p>
              Je hebt een belang in{' '}
              <strong>{d.name}</strong>.
            </p>
          </div>
        </div>

        <table>
          <tbody>
            {fieldsUBO.map((field) => {
              return (
                <tr key={field}>
                  <td style={{ fontSize: '0.8em', opacity: '0.5' }}>
                    {translations[field]}
                  </td>
                  <td style={{ fontSize: '0.9em', fontWeight: '500' }}>
                    {disclosedData[field]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ButtonContainer>
          <Button
            color="white"
            onClick={() => {
              navigate('/ubo')
            }}
          >
            Terug
          </Button>
          <Button>Verder</Button>
        </ButtonContainer>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {!disclosedData && (
        <div>
          <div>
            <h1 className={styles.title}>Deel je UBO-gegevens</h1>
          </div>
          {/* <p style={{ fontSize: '1.2em' }}>Deel je KVK bevoegdheidsgegevens</p> */}
          <div className={styles.irma}>
            <div id="irma-web-form"></div>
          </div>
          <ButtonContainer>
            <Button
              color="white"
              onClick={() => {
                navigate('/ubo');
              }}
            >
              Terug
            </Button>
          </ButtonContainer>
        </div>
      )}

      {disclosedData && renderDisclosedData()}
    </div>
  );
}
